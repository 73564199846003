import TextControlEditor from "@/components/CustomForm/Design/ControlEditor/TextControlEditor";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";

export default class DateControlEditor extends TextControlEditor{


  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
  }




}
