import DateControlEditor from "@/components/CustomForm/Design/ControlEditor/DateControlEditor";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";

export default class DateTimeControl extends DateControlEditor{


  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
  }
}
