

  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {CustomFormFieldCreateOrUpdateDto, SelectListItem} from "@/api/appService";
  import {IValidate} from "@/components/CustomForm/Validators/IValidate";
  import EmailValidator from "@/components/CustomForm/Validators/implements/EmailValidator";
  import MaxLengthValidator from "@/components/CustomForm/Validators/implements/MaxLengthValidator";
  import MaxValueValidator from "@/components/CustomForm/Validators/implements/MaxValueValidator";
  import MinLengthValidator from "@/components/CustomForm/Validators/implements/MinLengthValidator";
  import MinValueValidator from "@/components/CustomForm/Validators/implements/MinValueValidator";
  import MobilePhoneValidator from "@/components/CustomForm/Validators/implements/MobilePhoneValidator";
  import NumberValidator from "@/components/CustomForm/Validators/implements/NumberValidator";
  import {createValidator} from "@/components/CustomForm/common";

  @Component({
    name: 'FormControlValidatorPropertyEditor'
  })
  export default class FormControlValidatorPropertyEditor extends Vue {


    @Prop({required: true})
    field!: CustomFormFieldCreateOrUpdateDto;

    private defaultValidators: SelectListItem[] = [];
    private canSelectValidators: SelectListItem[] = [];

    private addedValidators: IValidate[] = [];


    created() {
      this.defaultValidators.push({value: 'email', text: '邮箱'});
      this.defaultValidators.push({value: 'mobilePhone', text: '手机号'});
      this.defaultValidators.push({value: 'maxLength', text: '字符最大长度'});
      this.defaultValidators.push({value: 'minLength', text: '字符最小长度'});
      this.defaultValidators.push({value: 'minValue', text: '最小值'});
      this.defaultValidators.push({value: 'maxValue', text: '最大值'});
      this.defaultValidators.push({value: 'number', text: '数值'});


      this.updateValidators();

    }

    updateValidators() {
      this.canSelectValidators = this.defaultValidators.map(s => s);
      this.addedValidators = [];
      if(this.field) {
        if (this.field.fieldValidators === undefined) {
          this.$set(this.field, 'fieldValidators', []);
        }
        if (this.field.fieldValidators && this.field.fieldValidators.length > 0) {
          for (let i = 0; i < this.field.fieldValidators!.length; i++) {
            this.addValidator(this.field.fieldValidators![i], false);
          }
        }
      }
    }

    handleAddValidatorCommand(command: string) {
      this.addValidator(command, true);


    }

    addValidator(expression: string, isAddToFieldValidator: boolean) {
      const validator = createValidator(expression);
      this.addedValidators.push(validator);
      for (let i = 0; i < this.canSelectValidators.length; i++) {
        if (this.canSelectValidators[i].value === expression) {
          this.canSelectValidators.splice(i, 1);
          break;
        }
      }

      if (isAddToFieldValidator) {

        this.field.fieldValidators!.push(validator.toString());
      }


    }

    handleDelete(index: number) {
      let validator = this.addedValidators[index];
      this.addedValidators.splice(index, 1);
      for (let i = 0; i < this.defaultValidators.length; i++) {
        let item = this.defaultValidators[i];
        if (item.value === validator.name()) {
          this.canSelectValidators.push(item);
        }
      }
    }


    @Watch('field')
    onFieldChange() {
      this.updateValidators();
    }

    @Watch('addedValidators', {deep: true})
    onAddedValueChange() {
      this.field.fieldValidators = [];
      for (let i = 0; i < this.addedValidators.length; i++) {
        this.field.fieldValidators.push(this.addedValidators[i].toString());
      }
    }

    isValidatorEffect() {
      if (!this.addedValidators.length) {
        return true;
      }
      for (let i = 0; i < this.addedValidators.length; i++) {
        let validator = this.addedValidators[i];
        let properties = validator.properties();
        if (properties.length) {
          for (let j = 0; j < properties.length; j++) {
            if (!properties[j].isPropertyValueSuccess()) {
              return false;
            }
          }
        }
      }
      return true;
    }


  }
