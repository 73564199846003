import AbstractControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractControlEditor";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import AbstractSelectControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractSelectControlEditor";

export default class RadioControlEditor extends AbstractSelectControlEditor{




}
