import AbstractControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractControlEditor";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";

export default class NumberControlEditor extends AbstractControlEditor{



  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
  }
}
