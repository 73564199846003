
  import {Component, Vue} from "vue-property-decorator";
  import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
  import {CustomFormType, InternalProjectCreateOrUpdateDto} from "@/api/appService";
  import api from '@/api/index';

  @Component({name:'ExternalProjectCustomFormDto',
    components: {CustomFormEdit}
  })
  export default class ExternalProjectCustomFormDemo extends Vue{


    dataId:number  = 0;


    created(){
      if(this.$route.params.id){
        this.dataId = parseInt(this.$route.params.id);
      } else {
        this.$message.error('url参数中缺少id');
      }
    }

    get hostType(){
      return CustomFormType.InternalProject;
    }

    get getData(){
      return api.internalProject.get({id:this.dataId});
    }

    handleSave(){
      const data  = (this.$refs.customForm as any).getRequestData() as InternalProjectCreateOrUpdateDto;
      api.internalProject.update({body:data}).then(res=>{
        this.$message.success('更新成功');
      });
    }



  }
