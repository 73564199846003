import {IControlEditor} from "@/components/CustomForm/Design/ControlEditor/IControlEditor";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import {Vue} from "vue-property-decorator";
import {ILayoutItem} from "@/components/CustomForm/common";
import {PropertyValueEditorType} from "@/components/CustomForm/ControlEditor/common";

export default class DividerEditor implements IControlEditor{

  public content!:ElementProperty;
  public labelPosition!:ElementProperty;

  private _layoutItem!:ILayoutItem;
  private _attributes:any;


  constructor(layoutItem: ILayoutItem) {
    this._layoutItem = layoutItem;
    if(!this._layoutItem.data.elementAttributes){
      this._layoutItem.data.elementAttributes = {};
    }
    this._attributes = this._layoutItem.data.elementAttributes;


    this.content = new ElementProperty('分割线中的内容(默认为空哦)');
    this.content.onPropertyValueChange = (newValue) => {
      this._layoutItem.data.elementContent = newValue;
      if(newValue){
        this.labelPosition.show = true;
        if(!this.contentPosition){
          this.labelPosition.propertyValue = this.labelPosition.defaultValue;
        }
      } else {
        this.labelPosition.show = false;
        this.contentPosition = undefined;
      }
    }



    this.labelPosition = new ElementProperty('内容对齐方式');
    this.labelPosition.valueEditor = PropertyValueEditorType.Radio;
    this.labelPosition.options = [{value:'left',text:'靠左'},{value:'center',text:'居中'},{value:'right',text:'靠右'}];
    this.labelPosition.show = false;
    this.labelPosition.defaultValue = 'left';
    if(layoutItem.data.elementContent){
      this.labelPosition.show = true;
    }
    if(!this.contentPosition){
      this.contentPosition = this.labelPosition.defaultValue;
    }
    this.labelPosition.onPropertyValueChange = (newValue)=>{
      this.contentPosition = newValue;
    }



    // set values
    this.content.propertyValue = this._layoutItem.data.elementContent;
    this.labelPosition.propertyValue = this.contentPosition;

  }

  getProperties(): ElementProperty[] {
    return [this.content,this.labelPosition]??this.labelPosition.defaultValue;
  }

  get contentPosition(){
    return this._attributes['content-position'];
  }

  set contentPosition(newValue:string | undefined){
    if(newValue){
      Vue.set(this._attributes,'content-position',newValue);
      // this._attributes['content-position'] = newValue;
    } else {
      Vue.delete(this._attributes,'content-position');
    }
  }



}
