import TextControlEditor from "./TextControlEditor";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";

export default class TextAreaControlEditor extends TextControlEditor{


  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
  }
}
