import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import {IControlEditor} from "@/components/CustomForm/Design/ControlEditor/IControlEditor";
import {PropertyValueEditorType} from "@/components/CustomForm/ControlEditor/common";


export default  class AbstractControlEditor implements IControlEditor{

  private _field!:CustomFormFieldCreateOrUpdateDto;

  public name!:ElementProperty;
  public formLabel!:ElementProperty;
  public required!:ElementProperty;
  public validators!:ElementProperty;

  constructor(field: CustomFormFieldCreateOrUpdateDto) {

    this.name = new ElementProperty('键名',undefined,field);
    this.name.isPropertyValueRequired = true;
    this.name.isPropertyValueCheckOnRealTime = true;
    this.name.propertyValue = field.fieldName;
    if(!field.isDynamic){
      this.name.readonly = true;
    }
    this.name.propertyValueValidate = (value) => {
      let result:string[] = [];
      if(!/^([a-zA-Z]{1}[\d\w_]{0,19})$/.test(value!)){
        result.push('键名必须是字母开头，且总长度不能超过20个字符')
      }
      return result;
    };
    this.name.onPropertyValueChange = (newValue) => {
      this.field.fieldName = newValue;
    }

    // formLabel init
    this.formLabel = new ElementProperty('标题',undefined,field);
    if(!field.isDynamic){
      this.formLabel.readonly = true;
    }
    this.formLabel.isPropertyValueRequired = true;
    this.formLabel.propertyValue = field.fieldDisplayName;
    this.formLabel.onPropertyValueChange = (newValue)=>{
      this.field.fieldDisplayName = newValue;
    }

    this.required = new ElementProperty('必填项',undefined,field);
    this.required.valueEditor = PropertyValueEditorType.Switch;
    this.required.isPropertyValueRequired = true;
    this.required.propertyValue = (field.required??false).toString().toLowerCase();
    this.required.onPropertyValueChange = (newValue)=>{
      this.field.required = newValue === 'true';
    }

    this._field = field;


    this.validators = new ElementProperty('数据验证器',undefined,field);
    this.validators.valueEditor = PropertyValueEditorType.Validators;

  }

  protected get field(){
    return this._field;
  }

  getProperties(): ElementProperty[] {
    return [this.name,this.formLabel,this.required];
  }


}
