import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import TextControlEditor from "./TextControlEditor";

import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import AbstractSelectControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractSelectControlEditor";

export default class AutoCompleteControlEditor extends AbstractSelectControlEditor {

  placeholder: ElementProperty;


  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
    this.placeholder = new ElementProperty('输入提示',undefined,this.field);
    this.placeholder.propertyValue = field.placeholder;
    this.placeholder.onPropertyValueChange = (newValue)=>{
      this.field.placeholder = newValue;
    };
  }


  getProperties(): ElementProperty[] {
    return [...super.getProperties(),this.placeholder];
  }
}
