

  import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
  import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
  import {ILayoutItem} from "@/components/CustomForm/common";
  import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
  import AbstractControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractControlEditor";
  import TextControlEditor from "@/components/CustomForm/Design/ControlEditor/TextControlEditor";
  import AutoCompleteControlEditor from "@/components/CustomForm/Design/ControlEditor/AutoCompleteControlEditor";
  import FormControlPropertyEditor from "@/components/CustomForm/Design/FormControlPropertyEditor.vue";
  import SelectControlEditor from "@/components/CustomForm/Design/ControlEditor/SelectControlEditor";
  import DateControlEditor from "@/components/CustomForm/Design/ControlEditor/DateControlEditor";
  import DateTimeControl from "@/components/CustomForm/Design/ControlEditor/DateTimeControl";
  import RadioControlEditor from "@/components/CustomForm/Design/ControlEditor/RadioControlEditor";
  import CheckboxControlEditor from "@/components/CustomForm/Design/ControlEditor/CheckboxControlEditor";
  import TextAreaControlEditor from "@/components/CustomForm/Design/ControlEditor/TextAreaControlEditor";
  import NumberControlEditor from "@/components/CustomForm/Design/ControlEditor/NumberControlEditor";
  import TitleEditor from "@/components/CustomForm/Design/ControlEditor/TitleEditor";
  import {IControlEditor} from "@/components/CustomForm/Design/ControlEditor/IControlEditor";
  import DividerEditor from "@/components/CustomForm/Design/ControlEditor/DividerEditor";
  import SimpleUploadImageEditor from "@/components/CustomForm/Design/ControlEditor/SimpleUploadImageEditor";
  import BatchUploadEditor from "@/components/CustomForm/Design/ControlEditor/BatchUploadEditor";
  import ProvinceCityAreaControlEditor from "@/components/CustomForm/Design/ControlEditor/ProvinceCityAreaControl";
  import ABswitch from "@/components/CustomForm/Design/ControlEditor/ABswitch";
  import FormControlValidatorPropertyEditor
    from "@/components/CustomForm/Design/FormControlValidatorPropertyEditor.vue";
  import BatchUploadImageEditor from "@/components/CustomForm/Design/ControlEditor/BatchUploadImageEditor";

  @Component({
    components: {FormControlPropertyEditor,FormControlValidatorPropertyEditor}
  })
  export default class FormControlProperties extends Vue {

    formControl?: CustomFormFieldCreateOrUpdateDto = {};
    isTitleRow?:boolean = false;
    public layoutItem?: ILayoutItem = undefined;

    show: boolean = false;

    private properties: ElementProperty[] = [];
    private _controllerEditor?: IControlEditor;



    @Watch('show')
    onShowChange(value: boolean) {
      if (!value) {
        return;
      }


      if (this.formControl&&!this.isTitleRow) {
        this._controllerEditor = undefined;
        switch (this.formControl!.elementType) {
          case 'input':
          case 'el-input':
            this._controllerEditor = new TextControlEditor(this.formControl!);
            break;
          case 'textarea':
          case 'el-textarea':
            this._controllerEditor = new TextAreaControlEditor(this.formControl!);
            break;
          case 'number':
          case 'el-number':
          case 'el-input-number':
            this._controllerEditor = new NumberControlEditor(this.formControl!);
            break;
          case 'el-select':
          case 'select':
          case 'dropdown':
          case 'dropdownList':
          case 'dropdown-list':
          case 'dropdownlist':
            this._controllerEditor = new SelectControlEditor(this.formControl!);

            break;
          case 'Multiple':
          case 'multiple':
          case 'MultipleSelect':
          case 'multipleSelect':
          case 'dropdown-multipleselect':
          case 'multiple-dropdown-list':
          case 'multipledropdownlist':
          case 'multipleDropdownList':
          case 'MultipleDropdownList':
            this._controllerEditor = new CheckboxControlEditor(this.formControl!);
            break;
          case 'el-radio':
          case 'radio':
            this._controllerEditor = new RadioControlEditor(this.formControl!);
            break;
          case 'el-checkbox':
          case 'checkbox':
            this._controllerEditor = new CheckboxControlEditor(this.formControl!);
            break;
          case 'autocomplete':
          case 'el-autocomplete':
          case 'el-input-autocomplete':
            this._controllerEditor = new AutoCompleteControlEditor(this.formControl!);
            break;
          case 'date':
          case 'datepicker':
          case 'el-date':
          case 'el-datepicker':
            this._controllerEditor = new DateControlEditor(this.formControl!);
            break;
          case 'datetime':
          case 'el-datetime':
          case 'el-datetime-picker':
          case 'datetime-picker':
            this._controllerEditor = new DateTimeControl(this.formControl!);
            break;
          case 'upload-simple-image':
          case 'simple-upload-image':
          case 'uploadSimpleImage':
          case 'simpleUploadImage':
            this._controllerEditor = new SimpleUploadImageEditor(this.formControl!);
            break;
          case 'batch-upload':
          case 'upload-batch':
            this._controllerEditor = new BatchUploadEditor(this.formControl!);
            break;
          case 'batch-upload-image':
          case 'upload-batch-image':
          case 'image-upload-batch':
            this._controllerEditor = new BatchUploadImageEditor(this.formControl!);
            break;
          case 'province-city-area-town-control':
          case 'ProvinceCityAreaTownControl':
          case 'provinceCityAreaTownControl':
            this._controllerEditor = new ProvinceCityAreaControlEditor(this.formControl!);
            break;
          case 'el-switch':
          case 'switch':
          case 'Switch':
            this._controllerEditor = new ABswitch(this.formControl!);
            break;

          case 'Ueditor':
          case 'editor':
          case 'u-editor':
          case 'ueditor':
          case 'el-editor':
            this._controllerEditor = new TextControlEditor(this.formControl!);
            break;
          default:
            break;
        }
      } else if(this.layoutItem&&this.isTitleRow){
        this._controllerEditor = undefined;
        // this.formControl = {};
        switch (this.layoutItem!.data.elementName) {
          case 'h1':
          case 'h2':
          case 'h3':
          case 'h4':
          case 'h5':
          case 'h6':
            this._controllerEditor = new TitleEditor(this.layoutItem!);
            break;
          case 'el-divider':
          case 'divider':
            this._controllerEditor = new DividerEditor(this.layoutItem!);
            break;
        }
      } else {
        this._controllerEditor = undefined;
      }

      this.properties = this._controllerEditor?.getProperties() ?? [];
    }

    get hasFormControlValue(){
      return !!this.formControl === true;
    }

    get title() {
      return '属性编辑';
    }

    handleClose(done:any) {
      if(!this.checkAllProperties()){
        this.$message.warning('有属性值未检验通过，请检查');
        this.show = true;
        return;
      } else if(!this.checkValidators()) {
        this.$message.warning('属性验证器配置不正确，请检查');
        this.show = true;
      } else {
        done();
      }
    }

    checkAllProperties(){
      let isAllPropertiesValid = true;
      for(let i = 0;i<this.properties.length;i++){
        if(this.properties[i].valueCheckErrors.length){
          isAllPropertiesValid = false;
          break;
        }
      }
      return isAllPropertiesValid;
    }

    checkValidators(){
      return (this.$refs.validatorPropertyEditor as any).isValidatorEffect() ;
    }

    get isFormControl(){
      return !this.isTitleRow;
    }

  }
